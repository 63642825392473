//FONTS
$mainFont: 'Passion One', cursive;
$secondaryFont: 'Cabin', sans-serif;

//COLORS
$orange: #F28F3B;
$blue: #4768B1;
$lightBlue: #BFD7EA;
$darkBlue: #04213F;

//Breakpoints
$large: 1690px;
$medium: 1023px;
$small: 640px;

//Styles

