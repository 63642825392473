@import 'partials/_reset.scss';
@import 'partials/_variables.scss';

/***************************************************
Style Sheet Styles
***************************************************/

h2 {
    line-height: 1;
    font-size: 80px;
    color: $darkBlue;
    font-weight: 600;
    font-family: $mainFont;
    text-transform: uppercase;

    span {
        color: $orange;
    }

}

h3 {
    font-size: 42px;
    color: $darkBlue;
    font-weight: 600;
    line-height: 35px;
    font-family: $mainFont;
    text-transform: uppercase;

    span {
        color: $orange;
    }

}

h4 {
    line-height: 1;
    font-size: 30px;
    color: $darkBlue;
    font-weight: 600;
    font-family: $secondaryFont;
    text-transform: uppercase;
    span {
        color: $orange;
    }
}

h5 {
    line-height: 1;
    font-size: 22px;
    color: $darkBlue;
    font-weight: 400;
    font-family: $secondaryFont;
}

h6 {
    font-size: 200px;
    line-height: .68;
    font-weight: 600;
    color: $lightBlue;
    font-family: $mainFont;
    text-transform: uppercase;
}

p {
    font-size: 18px;
    color: $darkBlue;
    line-height: 24px;
    font-family: $secondaryFont;
}

ul {
  font-size: 18px;
  color: $darkBlue;
  line-height: 22px;
  font-family: $secondaryFont;
}

a {
    font-size: 20px;
    line-height: 24px;
    color: $lightBlue;
    font-family: $secondaryFont;

    &:hover {
      color: $orange;
    }

}

.button {
    @extend a;
    color: $orange;
    padding: 4px 12px;
    background: transparent;
    border: 2px solid $orange;
    font-weight: bold;

    &:hover {
        color: #fff;
        background-color: $orange;
    }

}

.lg-btn {
    @extend .button;
    padding: 24px 48px;
}

.product-btn {
    @extend a;
    border: none;
    padding: 10px;
    color: $lightBlue;
    background: transparent;

    &:hover {
        color: #fff;
        background: $orange;
    }

}


/******************************
Utility Classes
******************************/

.fullwidth {max-width: none;}

strong {font-weight: 700;}

.no-padding {padding: 0;}

.no-case {
  text-transform: none;
  font-size: 32px;
  line-height: 32px;
}
h3.no-case {
  display: flex;
}
.white {color: #fff;}

.orange {color: $orange;}

.blue {color: $blue;}

.light-blue {color: $lightBlue;}

.dark-blue {color: $darkBlue;}

.text-center-large {text-align: center;}

.bg {
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(/assets/_img/colonial-bg.png);
}

.error {color: #ff0000;}


/******************************
Forms
******************************/
[type=color], [type=date], [type=datetime-local],
[type=datetime], [type=email], [type=month], [type=number],
[type=password], [type=search], [type=tel], [type=text],
[type=time], [type=url], [type=week], textarea, select {
  height: 30px;
  margin: 0 0 8px;
  color: $darkBlue;
  line-height: 30px;
  border-radius: 3px;
  padding: 0 1.25rem 0 .5rem;
  font-family: $secondaryFont;
  background-color: $lightBlue;
}

select {
  background-image: url("/assets/_img/arrow-down.png");
  background-size: 12px 14px;
  background-repeat: no-repeat;
}

textarea {
  min-height: 175px !important;
}


/******************************
Modal
******************************/
#register-modal,
#forgot-pass-modal {
  width: 100%;
  border: none;
  outline: none;
  max-width: 52rem;
  margin: 12px auto;
  padding: 22px 10px;
}

.reveal {
  width: 75%;
  padding: 1px;
  max-width: 75rem;
}

#register-btn:hover h4 {
  color: #fff;
}

#forgot-pass-modal h4 {
  text-transform: initial;
}


/******************************
Header
******************************/
.spacer {
    width: 100%;
    height: 80px;
    display: block;
    position: relative;
    transition: all .25s ease;
}

header {
    z-index: 4;
    width: 100%;
    position: fixed;
    background: #fefefe;
    padding: 20px 0 18px;
    transition: all .25s ease;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    #logo img {
      width: 100%;
      max-width: 275px;
      min-width: 200px;
      transition: all .25s ease;
    }

    .main-nav {
      text-align: center;
    }

    .auth {
      float: right;
      padding: 4px 0;
      min-width: 180px;
      white-space: nowrap;

      li {
          margin: 0 4px;
          display: inline-block;
          vertical-align: middle;

          a {
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
          }

      }

      .login-box, .cart-box {
        padding: 0;
        right: 20px;
        width: 300px;
        border: none;
        display: none;
        overflow: hidden;
        margin-top: 24px;
        position: absolute;
        background: #fefefe;
        transition: all .25s ease;
        box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.15);

        &:before {
          top: -10px;
          right: 33%;
          width: 45px;
          content: "";
          height: 45px;
          overflow: visible;
          position: absolute;
          background-size: 100%;
          transform: rotate(90deg);
          transition: all .25s ease;
          background-image: url('/assets/_img/diamond.png');
        }

        &.open {
          display: block;
          padding: 50px 0 0;
          overflow: visible;
        }

        label {
          color: $darkBlue;
          font-family: $secondaryFont;

          [type=text] {
            height: 24px;
            border: none;
            border-radius: 3px;
            background-color: $lightBlue;
          }

        }

        .forgot {
          font-size: 16px;
          color: $darkBlue;
          font-weight: 400;
          text-decoration: underline;
        }

        &-blue {
          float: left;
          width: 100%;
          padding: 22px 0;
          margin: 20px 0 0;
          background: $blue;

          a {
            color: #fff;
            display: block;
            padding: 4px 0;
            font-weight: 400;
            text-align: center;
            transition: all .25s ease;
            text-decoration: underline;
            border: 2px solid transparent;
            background-color: transparent;

            &:hover {
              text-decoration: none;
              border: 2px solid $orange;
              transition: all .25s ease;
            }

          }

        }

        .total {
          padding-top: 24px;

          h5 {
            font-weight: 700;
          }

        }

        .remove {
          width: 75px;
          text-decoration: underline;
        }

        .media-object-section {
          width: 25%;
          vertical-align: middle;

          p {font-size: 14px;}
          h5 {font-size: 20px;}

          &.main-section {
            width: 50%;
          }

        }

        .media-object-section:first-child {
          padding-right: 0;
          padding-left: 10px;
        }

        .media-object-section:last-child:not(:nth-child(2)) {
          padding-left: 5px;
        }

      }

      .cart-box:before {
        right: 7%;
      }

    }

    .menu li {
      display: inline-block;
      vertical-align: middle;

      a {
          color: #000;
          line-height: 1;
          font-weight: 600;
          padding: .7rem 1.7rem;
          transition: all 0.2s linear;

          &:hover {
              color: $orange;
              transition: all 0.2s linear;
          }

      }

    }

}

.alert-banner {
  background: $orange;
  padding: 24px 5%;
  text-align: center;

  @media (max-width: $medium) {
    padding: 15px 5%;
  }

  p {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;

    @media (max-width: $medium) {
      font-size: 20px;
      line-height: 24px;
    }
  }

}

/******************************
Is-Sticky
******************************/
.is-sticky {

  header {
    padding: 8px 0;
    transition: all .25s ease;

    #logo img {
      margin: 2px 0 0;
      max-width: 245px;
      transition: all .25s ease;
    }

  }

  .spacer {
    height: 62px;
    transition: all .25s ease;
  }

  header .auth .login-box,
  header .auth .cart-box {
    margin-top: 14px;
    transition: all .25s ease;

    &:before {
      top: 0px;
      transition: all .25s ease;
    }

  }

}



/* Expandable Search Form */
.search {
  top: 0;
  right: 0;
  width: 0;
  min-width: 100%;
  position: relative;

  &-box {
    right: 0;
    width: 0%;
    float: right;
    min-width: 40px;
    overflow: hidden;
    position: relative;

    &-input, &-input:focus {
      top: 0;
      right: 0;
      border: 0;
      margin: 0;
      outline: 0;
      width: 100%;
      height: 36px;
      font-size: 16px;
      color: $darkBlue;
      box-shadow: none;
      position: relative;
      background: $lightBlue;
      padding: 0px 45px 0px 8px;
      transition: all 0.3s linear;
      background: -webkit-linear-gradient(left, $lightBlue, #fefefe);
      background: linear-gradient(to right, $lightBlue, #fefefe);

      &::-webkit-input-placeholder {
        color: $darkBlue;
      }
      &:-moz-placeholder {
        color: $darkBlue;
      }
      &::-moz-placeholder {
        color: $darkBlue;
      }
      &::-ms-input-placeholder {
        color: $darkBlue;
      }

    }

    &-icon,
    &-submit {
      top: 0;
      right: 0;
      margin: 0;
      border: 0;
      outline: 0;
      color: $blue;
      display: block;
      cursor: pointer;
      background: #fff;
      text-align: center;
      position: absolute;
      transition: all 0.3s linear;
    }

    .fa-arrow-circle-o-right {
      top: 0;
      padding: 0 8px;
      font-size: 36px;
      line-height: 37px;
      visibility: hidden;
      transform: rotate(-180deg);
    }

    .fa-search {
      float: right;
      padding: 0 8px;
      font-size: 28px;
      line-height: 38px;
    }

    &-open {
      right: 0;
      z-index: 10;
      width: 100%;
      min-width: 350px;
      transition: all 0.3s linear;
      box-shadow: -40px 0px 6px #fefefe;

      .search-box-submit {
        z-index: 1;
        width: 100%;
        height: 100%;
        font-size: 0;
        text-indent: -9999px;
        background: transparent;
      }

      .search-box-icon,
      .search-box-submit {
        margin-right: 0;
        transition: all 0.25s;

        .fa-arrow-circle-o-right {
          visibility: visible;
          transform: rotate(0deg);
          transition: all 0.3s linear;
        }

        .fa-search {
          display: none;
        }

      }

    }

  }

}


/*============== Mobile Menu =================*/

.mobile-menu-wrap {
  position: relative;
  z-index: 901;
}

.nav-icon-wrap {
  display: none;
  top: 14px;
  right: 40px;
  width: 38px;
  height: 38px;
  z-index: 101;
  cursor: pointer;
  text-align: center;
  position: absolute;
  a {
    top: 0px;
    height: 100%;
    width: 100%;
    display: block;
    padding: 1px 0 0;
    position: relative;
    margin: -1px auto 1px;
  }
}

.hamburger {
  border: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  padding: 5px 0;
  cursor: pointer;
  overflow: visible;
  text-transform: none;
  display: inline-block;
  transition-duration: 0.15s;
  background-color: transparent;
  transition-property: opacity, filter;
  transition-timing-function: linear;
}

.hamburger-box {
  width: 35px;
  height: 26px;
  position: relative;
  display: inline-block;
}

.hamburger-inner {
  top: 50%;
  width: 35px;
  height: 4px;
  display: block;
  margin-top: -2px;
  border-radius: 4px;
  position: absolute;
  background-color: #000;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  &::before, &::after {
    width: 35px;
    height: 4px;
    content: "";
    display: block;
    border-radius: 4px;
    position: absolute;
    background-color: #000;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
  }
  &:before {
    top: -11px;
  }
}

.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.15s;
    transition-delay: 0.15s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &:after {
      top: -22px;
      transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }
    &:before {
      transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
  &.is-active .hamburger-inner {
    top: 23px;
    left: 1px;
    transition-delay: 0.32s;
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &:after {
      top: 0;
      opacity: 0;
      transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
    }
    &:before {
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

#my-menu {
  z-index: 2;
  transition: all 0.3s linear;
}

.mobile-menu {
  left: 0;
  top: 96px;
  width: 100%;
  display: none;
  position: absolute;
  #my-menu {
    width: 100%;
    z-index: 101;
    height: 100%;
    display: block;
    margin-top: 100px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: none;
}

.is-reveal-open .mm-slideout {
    z-index: 1000;
}

.mm-listview {
  > li > {
    a, span {
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
      padding: 22px 20px 5px;
      font-family: $secondaryFont;
    }
  }
  a {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    padding: 22px 20px 5px;
    font-family: $secondaryFont;
  }
}

.mm-panels > .mm-panel,
.mm-navbar.mm-hasbtns,
.mm-menu > .mm-navbar {
  background-color: $darkBlue;
}

.mm-panels>.mm-panel.mm-hasnavbar {
    padding-top: 65px;
}
.mm-listview {
  .mm-next + {
    a, span {
      font-size: 22px;
      line-height: 22px;
      padding: 22px 20px 5px;
    }
  }
  > li {
    > {
      a, span {
        padding: 22px 20px 5px;
      }
    }
    &:not(.mm-divider):after {
      left: 12px;
      right: 12px;
    }
  }
}

.mm-menu .mm-navbar {

  a {
    color: #fff;
    width: 100%;
    float: none;
    bottom: 120px;
    display: block;
    font-size: 22px;
    max-width: 125px;
    position: relative;
    margin: 0 0 25px 20px;

    &:hover {
      background-color: $orange;
    }

  }

  > * {
    color: #fff;
    width: 100%;
    float: none;
    bottom: 150px;
    display: block;
    font-size: 22px;
    max-width: 125px;
    position: relative;
    margin: 0 0 25px 20px;
  }

}

.mm-listview > li {
  border-color: $orange;
  .mm-next {
    border-color: $orange;
    &:before {
      border-color: $orange;
    }
  }
  &:after {
    border-color: $orange;
  }
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar>* {
    color: #fff;
    width: 100%;
    float: none;
    bottom: 160px;
    margin: 0 auto;
    display: block;
    font-size: 20px;
    max-width: 100%;
    position: relative;
}

.mm-menu .mm-navbar a.button {
    font-size: 18px;
    max-width: 220px;
    margin: -12px 22% 32px;
    padding: 0.5em 0.25em;

    .fa-shopping-cart {
      margin-right: 8px;
    }

}

.mm-panels>.mm-panel>.mm-listview:first-child,
.mm-panels>.mm-panel>.mm-navbar+.mm-listview {
    margin-top: 0px;
}


/******************************
Footer
******************************/

.subfooter-wrap{
  background-color: $blue;
  padding: 35px 0;

  h3 {
    padding: 22px 0;
  }

}

.subfooter{
  padding: 75px 0;

  h2{
    padding: 50px 0;
  }

  ul{
    margin: auto;

    li{
      padding: 10px 0;
      list-style-type: disc;
    }
  }
}

.main-footer{
  height: 100%;
  padding-top: 75px;
  background-color: $darkBlue;

  #logo img {
    width: 100%;
    max-width: 275px;
    min-width: 200px;
  }

  .divider{
    left: 16px;
    height: 150px;
    position: relative;
    border-right: 2px solid white;

    .address-1, .address-2 {
      text-align: left;
      padding-left: 30px;

        &:before {
        content: "";
        width: 52px;
        height: 52px;
        margin-left: -55px;
        position: absolute;
        background-image: url(/assets/_img/location_arrow.png);
        background-size: 35px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
      }

    }

  }

  h4 {
    font-size: 24px;
    text-transform: uppercase;

    span {
      color: $orange;
    }

  }

  .logo {
    width: 55px;
    float: right;
  }

  .environmental,
  .giving-back {
    margin-top: 40px;
    position: relative;

    h4 {
      font-size: 25px;

      &:hover {
        color: #F28F3B;
      }
    }

    p {
      max-width: 250px;
    }

    &:before {
      left: 23%;
      width: 50px;
      content: "";
      height: 50px;
      margin-top: -60px;
      position: absolute;
      background-image: url(/assets/_img/earth_logo.png);
      background-size: 50px;
      background-position: 0% 0%;
      background-repeat: no-repeat;
    }

  }

  .giving-back:before {
    background-image: url(/assets/_img/heart_logo.png);
  }

}

.footer-bottom {

  i {
    float: right;
    color: $orange;
    font-size: 40px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .footer-copy {
    font-size: 15px;
    padding-top: 10px;
    padding-left: 12px;

    a {
      font-size: 15px;
      padding-left: 12px;
    }

  }

}


/******************************
Home Page
******************************/
.home {

    &-top {
      top: 0;
      left: 0;
      width: 100%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $lightBlue;
      background-position: top center;
      background-image: url(/assets/_img/CP-home-header.png);
    }

    &-image {
      top: 140px;
      right: 30px;
      left: auto;
      margin: 0;
      width: 32%;
      height: 600px;
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: contain;
      background-image: url(/assets/_img/hero-packages.png);
    }

    &-bottom {
      // padding: 30px 0 18px;
      padding: 2% 0;
      background-color: $lightBlue;
    }

    img {
      right: 0;
      top: auto;
      left: auto;
      width: 100%;
      max-width: 600px;
      position: absolute;
    }

    .text-group {
      padding: 5% 0;
    }

    // h2 {
    //   padding: 12% 0;
    // }

    a {
      display: block;
      margin: 0 auto;
      max-width: 300px;
      margin-top: 20px;
    }

    h1 {
      float: left;
      width: 120px;
      height: 65px;
      font-size: 200px;
      line-height: 150px;
      padding: 0 0 0 10px;
      font-family: $mainFont;
      transform: rotate(180deg);
    }

}

.split-section {

  &-right, &-left {
    padding-top: 60px;
    padding-bottom: 60px;

    h2 {
      padding-bottom: 15px;
    }

    h5 {
      padding-bottom: 45px;
    }

    .column-block {
      max-width: 33.33%;
    }

  }

  &-left {

    img {
      display: block;
      margin: 0 auto 40px;
    }

    a {
      display: block;
      max-width: 320px;
      margin: 20px auto;
    }

  }

  &-right {
    min-height: 925px;
    position: relative;
    border-left: 2px solid #fff;

    @media (max-width: $medium) {
      border-left: none;
      border-top: 2px solid #fff;
    }

    > .row {
      max-width: 750px;
    }

    &:before {
      top: 50%;
      left: -41px;
      content: "";
      width: 80px;
      height: 80px;
      position: absolute;
      background: url(/assets/_img/or.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: $medium) {
        top: -41px;
        left: 45%;
      }

    }

  }

  .column-block {
    display: table;
    margin: 15px auto;

    a {
      background: url(/assets/_img/cartons.svg);
      color: #fff;
      width: 100%;
      padding: 20px 0;
      display: table-cell;
      vertical-align: middle;
      background-size: 48px 48px !important;
      background-position: 0 50% !important;
      background-repeat: no-repeat !important;
    }

    p {
      color: #fff;
      display: inline-block;
      padding-left: 25%;

      &:hover {
        color: $orange !important;
      }

    }

  }

}


/******************************
Inventory Icons
******************************/

.inventory-icons a.bags-on-rolls {
  background: url(/assets/_img/bags-on-rolls.svg);
}

.inventory-icons a.bags {
  background: url(/assets/_img/bags.svg);
}

.inventory-icons a.c-a-film {
  background: url(/assets/_img/c-a-film.svg);
}

.inventory-icons a.can-liners {
  background: url(/assets/_img/can-liners.svg);
}

.inventory-icons a.clear-film {
  background: url(/assets/_img/clear-film.svg);
}

.inventory-icons a.dispensers {
  background: url(/assets/_img/dispensers.svg);
}

.inventory-icons a.food-service {
  background: url(/assets/_img/food-service.svg);
}

.inventory-icons a.gusseted-poly-bags-case-packed {
  background: url(/assets/_img/gussetted-poly-bags.svg);
}

.inventory-icons a.industrial-bags-sheeting {
  background: url(/assets/_img/cartons.svg);
}

.inventory-icons a.lld-liners {
  background: url(/assets/_img/lld-liners.svg);
}

.inventory-icons a.layflat-cases {
  background: url(/assets/_img/cartons.svg);
}

.inventory-icons a.layflat-poly-bags-case-packed {
  background: url(/assets/_img/layflat-poly-bags.svg);
}

.inventory-icons a.mailing-shipping-postal-approved-lip-tape-bags {
  background: url(/assets/_img/shipping.svg);
}

.inventory-icons a.mailing-shipping {
  background: url(/assets/_img/shipping.svg);
}

.inventory-icons a.palletizing-products {
  background: url(/assets/_img/palletizing.svg);
}

.inventory-icons a.poly-tubing {
  background: url(/assets/_img/poly-tubing.svg);
}

.inventory-icons a.pre-opened-bags-on-rolls {
  background: url(/assets/_img/pre-opened-on-rolls.svg);
}

.inventory-icons a.reclosable-bags {
  background: url(/assets/_img/recloseable-bags.svg);
}

.inventory-icons a.shrink-bags-film {
  background: url(/assets/_img/shrink-bags.svg);
}

.inventory-icons a.specialty-packaging {
  background: url(/assets/_img/specialty.svg);
}

.inventory-icons a.specialty-products {
  background: url(/assets/_img/specialty.svg);
}

.inventory-icons a.specialty {
  background: url(/assets/_img/specialty.svg);
}

.inventory-icons a.static-control-products {
  background: url(/assets/_img/static-control.svg);
}

.inventory-icons a.zip-top-bags {
  background: url(/assets/_img/zip-top.svg);
}


/******************************
Staff
******************************/

.staff {
  margin-bottom: 20px;

  &-group {
    width: 100%;
    max-width: 1200px;
    position: relative;
    text-align: center;
    padding: 0 0px 75px;
  }

  &-card {
    width: 24%;
    vertical-align: top;
    display: inline-block;
    float: none !important;

    &.is-active .staff-card-diamond {
      transform: rotate(90deg);
      transition: all 0.3s linear;
    }

    &-diamond {
      left: 38%;
      width: 65px;
      height: 65px;
      bottom: -33px;
      display: block;
      margin: 0 auto;
      position: absolute;
      transform: rotate(0deg);
      transition: all 0.3s linear;
    }

  }

  .accordion, .tabs {
    margin: 0 auto;

    &-item {
      padding: 0 30px;
      max-width: 300px;
      margin: 30px 0 0;
      transition: all 0.3s ease;

      &.is-active .accordion-content {
        visibility: visible;
      transition: all 0.3s ease-out;
      }

    }

    &-title {
      margin: 0 0 30px;
      position: relative;
      padding: 0 0 25px 0;
      background-color: $darkBlue;

      &:before {content: none;}

      .profile {
        top: 0;
        left: 0;
        width: 100%;
        height: 275px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
      }

      .tag {
        min-height: 85px;
        padding: 15px 15px 0;
      }

      h5 {
        padding: 0;
        text-align: left;
      }

      p {
        padding: 0;
        text-align: left;
        line-height: 18px;
        padding: 5px 0 10px;
      }

    }

    &-content {
      left: 0;
      z-index: 1;
      width: 100%;
      margin: 0 auto;
      padding: 35px 5%;
      position: absolute;
      border-radius: 3px;
      visibility: hidden;
      display: block !important;
      background-color: $lightBlue;
      border: 1px solid transparent;
      transition-delay: .25s;

      h4 {
        padding: 0 0 5px;
        text-align: left;
        text-transform: capitalize;
      }

      h5 {
        color: $blue;
        text-align: left;
        padding: 0 0 20px;
        line-height: 25px;

        span a {
          font-size: 18px;
          color: $darkBlue;
        }
      }

      p {
        text-align: left;
        padding: 0 0 5px;
      }

    }

  }

  h2 {
    padding: 75px 0;
  }

  h3 {
    padding: 50px 0 25px;
  }

  p {
    text-align: center;
  }

}



/******************************
Custom Packaging
******************************/

.subpages{

  h2 {
    text-align: center;
    text-transform: uppercase;
    padding: 75px 0;
  }

  .badge {
    right: 8%;
    left: auto;
    z-index: 1;
    top: 9%;
    width: 100%;
    max-width: 200px;
    position: absolute;
    background: transparent;
    @media (max-width: $medium){
        max-width: 130px;
        top: 5.5%;
      }
  }

  .contact_ctarow_packaging {
    background: $orange;

    .button {
      border-color: #fff;

      &:hover,
      &:focus,
      &:active {
        background: darken($orange,5%);
      }
    }
  }

  .packaging-list {
    margin: 20px;
    padding-left: 20px;

    li {
      font-weight: 600;
      margin-bottom: 20px;
      list-style: disc;
    }
  }

  .technology-list {
    margin: 20px;
    padding-left: 20px;

    li {
      font-weight: 400;
      margin-bottom: 20px;
      list-style: disc;
    }
  }

  .row_packaging {
    padding: 20px 0;
    margin: 0 20px 0 0;

    @media (max-width: $medium){
        padding: 20px 0 0 0;
        margin: 0;
    }
  }

  .row_packing-flex {
    display: flex;

    @media (max-width: $medium){
        display: block;
    }
  }
  .box-image_packaging:before {
    display: none;
  }

  .box-image_packaging {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .background-notcover {
    background-size: 95% !important;
  }
  .box-img_packaging {
    padding: 20px 0;

    @media (max-width: $medium){
        padding: 20px 0 0 0;
    }
  }

  .row_packaging_list {
    padding: 40px 0;
  }

  .row_packaging_last {
    background: $lightBlue;

    .box-image:before {
      display: none;
    }
  }

  .packaging-steps {
    margin: 8px 0;

    p {
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 8px;
    }
  }

  .box-text {
    position: relative;
    padding-top: 150px;
    padding-bottom: 40px;

    h3 {
      margin-bottom: 15px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
    }

    ul {
      list-style-type: disc;
      margin: .75rem 0 1rem 2.25rem;
    }

    .subpage-button {
      margin-top: 25px;
    }

    &_packaging {
      padding-top: 40px;
    }

    &_packaging-list {
      padding-top: 40px;

      @media (max-width: $medium){
        padding: 0 15px;
      }
    }

    &_technology-list {
      padding-top: 80px;
      padding-bottom: 40px;

      @media (max-width: $medium){
        padding: 30px 15px;
      }
    }
    &_packaging-list-left {
      padding-top: 100px;

      @media (max-width: $medium){
        padding-top: 0;
      }
    }

  }

  .content-box {
    position: relative;

    &:before {
      top: 42%;
      left: -35px;
      content: "";
      right: auto;
      width: 70px;
      height: 70px;
      position: absolute;
      background-image: url(/assets/_img/diamond.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
      @media (max-width: $medium){
        display: none;
      }
    }


  }

  .content-bg {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center -4px;
  }

  .box-image {
    position: relative;

    &:before {
      top: 42%;
      left: -35px;
      content: "";
      right: auto;
      width: 70px;
      height: 70px;
      position: absolute;
      background-image: url(/assets/_img/diamond.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: $medium){
        display: none;
      }
    }

    div {
      width: 100%;
      min-height: 570px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

  }

  .no-box-image{
      position: relative;

      &:before{
        display: none;
      }

      div {
      width: 100%;
      min-height: 570px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

}

.subpages-2 {

  h2 {
      text-align: center;
      text-transform: uppercase;
      padding: 75px 0;
  }

  h3 {
    margin-bottom: .5rem;
  }

  .fullwidth{
    padding: 50px 0;
  }

  .subpage_two{
    width: 85%;
    margin: 0 auto !important;

    img {
      width: 100%;
      max-width: 525px;
      padding: 0 20px 20px 0;
    }

  }

}

/******************************
Products
******************************/

.products {

  > .row {
    max-width: 90rem;
    padding: 50px 0 75px;
  }

  h2 {
    padding: 75px 0px 15px;
    text-align: center;
  }

  h3 {
    padding: 0px 0px 75px;
    text-align: center;
  }

  table thead {
    border-bottom: 2px solid $lightBlue;
  }

}

// tabs
.product-tabs {
  padding: 0;

  .tabs-panel.is-active {
    display: block;
  }

  .tabs {
    margin: 0;
    border: none;
    padding: 15px 0 30px;
    list-style-type: none;
    background: transparent;
  }

  .tabs-title {
    width: 50%;
    float: none;
    max-width: 150px;
    text-align: center;
    display: inline-block;
  }

  .tabs-content {
    border: none;
    border-top: 0;
    margin: 0 auto;
    color: inherit;
    max-width: 525px;
    background: transparent;
  }

  .tabs-panel {
    padding: 0;
  }

  .tabs-title>a {
    margin: 2px 6px;
    border-radius: 6px;
    padding: 5px 8px 4px;
    transition: all 0.3s ease;
    border: 2px solid $orange;
  }

  .tabs-title>a:hover,
  .tabs-title>a:focus,
  .tabs-title>a[aria-selected=true] {
    outline: 0;
    color: #fff;
    background: $orange;
    transition: all 0.3s ease;

    h5 {
      color: #fff;
      transition: all 0.3s ease;
    }

  }

  &.mobile {
    display: none;

    .button {
      font-size: 16px;
      line-height: 20px;
      border-radius: 6px;
      margin: 15px auto 0;
    }

    h5 {
      padding: 4px 0;
      font-size: 18px;
      line-height: 22px;
    }

    p {display: inline-block;}

    hr {
      width: 100%;
      height: 18px;
      max-width: 95%;
      margin: 10px auto;
      border-bottom: 2px solid $orange;
    }

  }

}


.desktop {
  display: block;
}


.category {

  h3.title {
    padding: 0;
  }

}

.cat-card {
    padding: 10px;
    display: block;
    max-width: 250px;
    position: relative;
    margin: 0 auto 35px;
    border: 2px solid $orange;
    transition: all 0.3s ease;

    &:hover {

      span {
        transition: all 0.3s ease;
        background-color: $orange;
      }

      p {
        color: #fff;
        transition: all 0.3s ease;
      }

    }

  .bg-image {
    width: 100%;
    min-height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  p {
    font-weight: 600;
    padding: 16px 0 8px;
    transition: all 0.3s ease;
  }

  span {
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 50%;
    position: absolute;
    transition: all 0.3s ease;
    background-color: transparent;
  }

}



/******************************
Product Menu
******************************/
.product-menu {
  padding: 0;
  width: 100%;
  max-width: 425px;
  margin: 0 auto 75px;
  background-color: $darkBlue;

  li {
    position: relative;

    a {
      padding: 0 18px;
      line-height: inherit;
      text-transform: lowercase;

      &:hover {
        color: #fff;
        transition: all .25s ease;
        text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
      }

      &:after {
        display: none;
      }

    }

  }

  li .msub-menu {
    margin: 0 auto;

    &.is-active {
      box-shadow: 0 0 12px $darkBlue;
      background: rgba(0, 0, 0, 0.25);

      a:after {
        right: 6px;
        top: -20px;
        float: right;
        position: relative;
      }

    }

  }

  .menu.nested { //2nd level
    margin: 0 auto;
    background: $blue;

    .menu.nested { //3rd level
      color: $darkBlue;
      background: $lightBlue;

      h5 {
        color: $darkBlue;

        &:hover {
          text-shadow: 0px 0px 18px rgba(71, 104, 177, 0.75);
        }

      }

      .menu.nested { //4th level
        background: #fff;
      }

    }

  }

  h5 {
    padding: 10px 0 0;
    color: $lightBlue;
    text-transform: lowercase;
    transition: all .25s ease;

    &:hover {
      color: #fff;
      transition: all .25s ease;
      text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
    }

    &:after {
      width: 100%;
      content: "";
      display: block;
      padding: 10px 0 0;
      position: relative;
    }

  }

  .submenu-toggle {
    top: 3px;
    right: 0;
    outline: 0;
    width: 40px;
    height: 40px;
    padding: 10px;
    position: absolute;
    text-indent: -99999px;

    // &:after {
    //   top: 50%;
    //   width: 0;
    //   height: 0;
    //   right: 10px;
    //   content: "";
    //   display: block;
    //   margin-top: -3px;
    //   border: 6px inset;
    //   position: absolute;
    //   border-bottom-width: 0;
    //   border-top-style: solid;
    //   transform: rotate(-90deg);
    //   transition: all 0.3s linear;
    //   border-color: #F28F3B transparent transparent;
    // }

    &[aria-expanded=true] span:after {
      transform: rotate(0deg);
      transition: all 0.3s linear;
    }

  }

  li li .submenu-toggle {
    top: 13px;

    &:after {
      border-color: #8cd1ff transparent transparent;
    }

  }

  li li li .submenu-toggle {

    &:after {
      border-color: rgba(0, 102, 171, 0.8) transparent transparent;
    }

  }

}




/******************************
Contact
******************************/

.contact {

  .bg h2 {
    padding: 75px 0;
  }

  .office-location {
    cursor: pointer;
    background: $lightBlue;
    color: $darkBlue;

    &.is-active,&.active{
      background: $blue;
      color: #fff;
      h4,p {
        color: #fff;
      }
    }

    padding: 20px 20px 30px;

    h4 {
      padding-bottom: 8px;
      text-transform: capitalize;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }

  }

  #mapid {
    z-index: 1;
    height: 100%;
    min-height: 475px;
  }

  .leaflet-bottom {
    visibility: hidden;
  }

  .leaflet-map-pane {
    z-index: 2 !important;
  }

  .leaflet-google-layer {
    z-index: 1 !important;
  }

  .is-active.sumter,
  .is-active.columbia {
    z-index: 10;
    position: relative;
    transition: all 0.25s;

    &:after {
      left: 45%;
      width: 45px;
      content: "";
      height: 45px;
      bottom: -23px;
      overflow: visible;
      position: absolute;
      background-size: 100%;
      transform: rotate(90deg);
      background-image: url('/assets/_img/diamond.png');
    }

  }

  .tabs {
    margin: 0;
    border: none;
    list-style-type: none;
    background: transparent;

    .tabs-title {
      float: none;

      > a {
        padding: 0;
        display: block;
        line-height: 1;
        color: inherit;
        font-size: inherit;
      }

    }

  }

  .tabs-content {
    border: none;
    border-top: 0;
    color: inherit;
    background: transparent;
    transition: all .5s ease;

    .tabs-panel {
      padding: 0;
    }

  }

  .form-wrapper {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;

    .split-left {padding-right: 8px;}
    .split-right {padding-left: 8px;}

  }

  .contact-entry {
    padding: 0 50px 75px;
    background: $lightBlue;

    h3 {
      padding-top: 40px;
      padding-bottom: 24px;
    }

    h4 {
      font-size: 25px;
      margin-bottom: 2px;
      text-transform: initial;
    }

    a {
      color: $darkBlue;
    }

    .fa {
      color: $orange;
    }

    hr {
      width: 65%;
      margin: 10px 0 15px;
      border-bottom: 2px solid $orange;
    }

  }

}



/******************************
Login
******************************/
.login {

  .bg h2 {
    padding: 75px 0;
  }

  .content {
    padding: 75px 0;

    h4 {
      font-weight: 400;
      padding: 10px 0;
      text-transform: initial;
    }

    h5 {
      width: 100%;
      padding: 10px 0;
    }

    hr {
      width: 100%;
      margin: 0 0 20px;
      border-bottom: 2px solid $lightBlue;
    }

    a {
      margin: 20px 15px 15px 0;
    }

  }

  .split-right {
    position: relative;

    @media (max-width: $medium) {
      padding-top: 75px;
    }

    &:before {
      top: -20px;
      left: -23%;
      content: "";
      height: 108%;
      position: absolute;
      border-left: 2px solid $lightBlue;
    }

  }

  .summary {
    padding: 22px 0 12px;
    border: 2px solid $lightBlue;

    p {
      padding: 6px 0;

      &.text-left {
        padding-left: 8px;
      }

    }

    hr {
      margin: 0;
    }

  }

}


/******************************
Table
******************************/
table tbody td, table tbody th,
table tfoot td, table tfoot th,
table thead td, table thead th {
  height: 65px;
  font-weight: 400;
  vertical-align: middle;
  font-family: $secondaryFont;
  padding: 0.5rem 0.625rem 0.625rem 0;
}

table tbody, table tfoot, table thead {
  border: none;
  color: inherit;
  background: transparent;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 2px solid $lightBlue;
  background-color: transparent;
}

#product_quantity {
  height: 24px;
  margin: 0 auto;
  max-width: 54px;
  display: inline-block;
  padding: 0 .25rem 0 .5rem;
}

table h5 {
  font-size: 20px;
}

.login .content table {
  color: $darkBlue;

  a {
    font-size: 14px;
    color: $darkBlue;
    font-weight: 600;
    vertical-align: middle;
    margin: 20px 15px 15px 10px;
  }

  .desc {

    .product {
      width: 70px;
      float: left;
      height: 45px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    h5 {
      width: 40%;
      padding: 0;
      float: left;
      display: block;
      font-size: 20px;
    }

    p {
      width: 50%;
      float: left;
      display: block;
      font-size: 14px;
      white-space: nowrap;
    }

  }

}

.login.cart .split-right:before {
  left: -16%;
}


/*****************************
Thank You
*****************************/

.thank-you{

  .bg h2{
    padding: 75px 0;
  }
}


/******************************
Media Query
******************************/

@media screen and (max-width: 1463px){

  .subfooter h2 {
    padding: 10px 0;
  }

} // end 1463

@media screen and (max-width: 1375px){

  .split-section-right,
  .split-section-left {
    padding: 60px 30px;
  }

  .split-section .column-block {

    a {
      text-align: left;
      background-position: 49% 0;
    }

    p {
      display: inline-block;
      padding-left: 32%;
      vertical-align: middle;
    }

  }

  header .menu li a {
    padding: .7rem 1.25rem;
  }

} // end 1375


@media screen and (max-width: 1285px){

  header .menu li a {
      padding: .7rem;
      letter-spacing: -0.5px;
  }

  .spacer {
    height: 82px;
  }

} // end 1285


@media screen and (max-width: 1210px){

  .subfooter-wrap {

    h3 {
      padding: 6px 0;
    }

    .button {
      white-space: nowrap;
    }

  }

}// end 1210


@media screen and (max-width: 1200px){

  .no-case {
    font-size: 28px;
    line-height: 28px;
  }

  .staff-card {
    width: 33%;
  }
  .staff .accordion-item {
    padding: 0 15px;
  }

  .split-section .column-block p {
    font-size: 16px;
    line-height: 20px;
    padding-left: 36%;
  }

} // end 1200


@media screen and (max-width: 1117px) {

  header .menu li a {
    font-size: 18px;
    line-height: 22px;
    padding: .7rem .5rem;
  }

} // end 1117


@media screen and (max-width: 1023px) {

  html, body {
    overflow-x: hidden ;
  }

  h2 {
    font-size: 68px;
    line-height: 55px;
  }

  h3 {}
  h4 {}
  h5 {}

  p {
    line-height: 22px;
  }

  // .home h2 {
  //   padding: 75px 0;
  // }

  .split-section-right,
  .split-section-left {
    padding: 60px;
  }

  .split-section .column-block p {
    font-size: 18px;
    line-height: 24px;
    padding-left: 27%;
  }

  header {
    padding: 8px 0;

    #logo img {
      width: 225px;
      margin-top: 6px;
    }

  }

  .spacer {
    height: 63px;
  }

  .nav-icon-wrap {
    display: block;
  }

  .is-sticky .nav-icon-wrap {
    top: 11px;
  }

  .subfooter{

    ul{
      margin-left: 20%;
    }
  }

  .main-footer{

    .divider{
      border: none;
    }

    .environmental, .giving-back, p, h4{
      margin: 0;
      text-align: center;
    }
    .environmental{
      padding-top: 75px;
      padding-bottom: 50px;

      &:before{
        margin-top: -60px;
        left: 46%;
      }

      h4, p{
        padding-left: 0;
      }
      p{
        margin: auto;
      }

    }

    .giving-back{
      padding-top: 75px;
      padding-bottom: 50px;

      &:before{
        margin-top: -60px;
        left: 46%;
      }

      h4, p{
        padding-left: 0;
      }
      p{
        margin: auto;
      }

    }
    img{
      display: block;
      margin: 0 auto;
    }
    .logo{
    display: block;
    margin: 0 auto;
    float: none;
    }
  }

  .contact .form-wrapper {
    .split-left, .split-right {
      padding: 0;
    }
  }

  .split-section-right,
  .split-section-left {
    top: 0;
    left: 0;
    content: "";
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(/assets/_img/colonial-bg.png);
  }

  .subfooter h2 {
    padding: 0 0 25px;
  }

  .contact .contact-entry {
    padding: 0 0px;
  }

  .subfooter-wrap h3 {
    padding: 0 0 30px;
  }

  .split-section-right {
    min-height: 100%;
  }

  .products-right {
    float: none !important;
    margin: 0 auto;
  }


} // end 1023


@media screen and (max-width: 924px){

  .staff-card {
      width: 48%;
  }

} // end 924


@media screen and (max-width: 800px){

  .product-tabs.mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

}

@media screen and (max-width: 760px){

  .no-case {
    font-size: 24px;
    line-height: 24px;
  }

  .split-section-right,
  .split-section-left {
    padding: 60px 0;
  }

}

@media screen and (max-width: 640px){

  h2 {
    font-size: 35px;
    line-height: 30px;
  }

  h3 {
    font-size: 28px;
    line-height: 26px;
  }

  h4 {
    line-height: 24px;
    font-size: 22px;
  }

  h5 {
    line-height: 22px;
    font-size: 20px;
  }

  .lg-btn {
    padding: 16px 48px;
  }

  .home h1 {
    float: left;
    width: 40px;
    height: 10px;
    font-size: 75px;
    line-height: 30px;
    padding: 0 0 0 0px;
  }

  .home a {
    max-width: 260px;
  }

  .split-section-left a,
  .split-section-left .button,
  .split-section-left .lg-btn,
  .split-section-left .product-btn {
    max-width: 260px;
    margin: 0px auto 20px;
    padding: 18px 48px;
  }

  .subfooter {
    padding: 45px 0;

    h2 {
      padding: 0;
    }

  }

  .split-section-right:before {
    left: 39%;
  }

  .text-center-large {
    text-align: left;
  }

  .text-center-small {
    text-align: center;
  }

  .text-right-small {
    text-align: right;
  }

  .mm-menu .mm-navbar a.button,
  .mm-menu .mm-navbar .button {
    margin: 0 18px 12px;
  }

  .mm-panels > .mm-panel > .mm-listview:first-child,
  .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: -18px;
  }

  .bg {
    background-size: 150%;
  }

  .subfooter {

    h3 {
      margin-bottom: 30px;
    }

    ul {
      margin-left: 40px;
    }

  }

  .main-footer {

    &.bg {
      background-size: cover;
    }

    .environmental:before {
      left: 42%;
    }

    .giving-back:before {
      left: 42%;
    }

    .divider{
      padding-top: 10px;

      .address-1,
      .address-2 {
        text-align: center;
      }

    }

  }

  .footer-bottom .footer-copy {
    padding: 0 0 18px;

    a {
      display: block;
    }

  }

  .contact .form-wrapper {
    width: 94%;
  }

  .login .content table {
    margin: 0px auto 25px;
  }

  .subpages .box-image div {
    min-height: 300px;
  }

  .split-section .column-block p {
    font-size: 16px;
    line-height: 20px;
    padding-left: 38%;
  }

  .subpages .box-text {
    padding-top: 85px;
  }

  .contact .contact-entry {
    padding: 50px 0;

    hr {
      width: 250px;
    }

  }

  .staff-card {
    width: 100%;
  }

  .contact .contact-entry h4 {
    line-height: 24px;
    font-size: 22px;
  }


} //end 640

@media screen and (max-width: 480px){

  header #logo img {
    width: 185px;
    margin-top: 6px;
    min-width: 180px;
  }

  .nav-icon-wrap {
    top: 11px;
    right: 22px;
  }

  .spacer {
    height: 56px;
  }

  .bg {
    background-size: 220%;
  }

  table h5 {
    font-size: 15px;
    font-weight: 600;
  }

  .no-case {
    font-size: 22px;
    line-height: 22px;
  }

  .split-section .column-block {

    a {
      text-align: center;
      background-position: center top !important;
    }

    p {
      padding-left: 0;
      padding-top: 30%;
    }

  }

} //end 480




/* Landscape */
@media only screen
  and (max-device-width: 736px)
  and (orientation: landscape) {

  .mm-listview,
  .mm-listview>li {
    width: 100%;
    display: block;
    margin: 0 10px;
    max-width: 175px;
  }

  .mm-menu .mm-navbar a.button {
    top: -260px;
    font-size: 15px;
    max-width: 185px;
    margin: 0px 50% 20px;
    padding: 0.35em 0.5em;
  }

  .mm-panels>.mm-panel>.mm-listview:first-child,
  .mm-panels>.mm-panel>.mm-navbar+.mm-listview {
    margin-top: 0px;
  }

  .mm-listview > li > a {
    font-size: 18px;
    line-height: 18px;
    padding: 24px 10px 6px;
  }

  .mm-panels > .mm-panel.mm-hasnavbar {
    padding-top: 65px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .mm-menu .mm-navbar a.cart .fa {
    font-size: 20px;
  }

  .mm-hasnavbar-bottom-1 .mm-panels,
  .mm-navbar-bottom-2 {
    bottom: 0px;
  }

  .mm-navbar {
    height: 0px;
  }

}


@media only screen
  and (max-device-width: 670px)
  and (orientation: landscape) {

  .mm-menu .mm-navbar a.button,
  .mm-menu .mm-navbar .button {
    top: -222px;
  }

}


/* Landscape */
@media only screen
  and (max-device-width: 568px)
  and (orientation: landscape) {

  .mm-menu .mm-navbar a.button {
    top: -200px;
  }

  .mm-panels > .mm-panel > .mm-listview:first-child,
  .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: -16px;
  }

}


.temp-button{
  &:hover{
    background: white;
    color: #04213F;
  }
  padding: 20px 30px;
}